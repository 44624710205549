<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 1200 1227"
  ><path
    fill="currentColor"
    d="M714.2 519.3 1160.9 0H1055L667.1 450.9 357.3 0H0l468.5 681.8L0 1226.4h105.9l409.6-476.2 327.2 476.2H1200L714.1 519.3Zm-145 168.5L521.7 620 144 79.7h162.6l304.8 436 47.5 67.9 396.2 566.7H892.5L569.2 687.9Z"
  /></svg>
</template>
